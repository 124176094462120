<template>
  <v-btn
    :loading="loading"
    text
    icon
    @click="onSave"
    class="ma-2"
  >
    <IconEdit v-if="!editMode && !loading" />
    <v-icon v-else color="primary">mdi-check-circle-outline</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "EditButton",
  props: {
    editMode: {
      type: Boolean,
    },
    loading: {
      type: Boolean
    }
  },
  components: {
    IconEdit: () => import("~cp/Icons/IconEdit"),
  },
  methods: {
    onSave() {
      if (this.editMode) {
        this.$emit('onSave');
      }
    },
  },
};
</script>

<style></style>
